/*
Theme Name: Affiliated Dermatology Theme
Theme URI: http://creativepickle.com/
Description: Custom Theme for Affiliated Dermatology
Author: CreativePickle
Version: 1.0
Tags: 
*/

@import url('reset.css');
@import (less) "mixins.less";

/****LAYOUT****/

body > #container {
     flex: 1 0 auto;
  width: 100%;
  position:relative;
  overflow:hidden;
  overflow-x:hidden!important;
  margin:0;
  padding:0;
  &:after{
      content: '\00a0'; /* &nbsp; */
    display: block;
    height: 0px;
    visibility: hidden;
      }
    }

body{
    overflow-x:hidden;
    background:#fff url(images/bg.png) repeat 0 0;
    display: flex;
  flex-direction: column;
  height: 100vh; /* 1, 3 */
 min-height:100%;
    &.page-template-homepage.php{
        
    }
}

.central{
    width:100%;
    max-width:@centralWidth!important;
    margin-left: auto;
    margin-right:auto;
    padding:0 2% 0 2%!important;
    position:relative;
    }


#main {
    width:@mainWidth;
	padding:0;
	margin:0px auto;
	 padding-top:110px;
    }
    
#content{
    width:@mainWidth;
	padding:0;
	.bodyfont();
	&.no-pad{
	    padding:0;
	    }
	}
	
#content-wrap{
   padding-bottom:40px!important;
   &.no-pad{
       padding-bottom:0!important;
       }
    }

#main-content{
	padding-right:40px;
	padding-bottom:40px;
    line-height:1.5;
    .bodyfont();
    color:@content;
   
    &.no-pad{padding-bottom:0;}
    &.wide{
        padding-right:0;
        border:none!important;
        }
    ul{
        padding:10px 0 10px 0;
        li{
            background:url(images/bullet.png) no-repeat 0 0px;
            padding:0 0 0 20px;
            margin-bottom:8px;
            i.fa-chevron-right{color:@blueLight;margin-right:8px;}
            }
        }
    ol{
	counter-reset: my-counter;
	padding-left:0!important;
    li{
        position:relative;
        padding-left:40px!important;
        margin-bottom:20px;
        padding-top:5px;
        &:before {
	content: counter(my-counter);
	counter-increment: my-counter;
	width:30px!important;
	height:30px!important;
	line-height:1!important;
	display:block;
	background:@blueLight;
	color:#fff;
	.centered();
	.rounded-corners(50%);
    line-height:30px!important;
	position:absolute;
	.montserrat(15px);
	top:0;
	left:0;
}
        }
}
    img{
        border:3px solid @grayLight;
        max-width: 100%;
        height: auto;
        }
    h2{
        .merriweather(28px);
        color:@blueDark;
        letter-spacing:.04em;
        padding:10px 0 20px 0;
        }
    h3{
        .montserrat(20px);
        color:@green;
        padding:10px 0 20px 0;
        .uppercase();
        letter-spacing:.04em;
        font-weight:400;
        }
    a{
        color:@green;
        &:hover{
            color:@greenMed;
            }
        }
    }

.accordion-wrap{
    margin-top:35px;
    }

.item.toggler{
    padding-bottom:20px!important;
    margin-bottom:20px!important;
    border-bottom:1px solid @grayLight;
    .trigger{
        h2{
            margin:0!important;
            padding:5px 0 0 60px!important;
            .montserrat(24px)!important;
            color:@blueDark!important;
            position:relative;
            .toggle-icon{
                .inline-block();
                background:rgba(44, 56, 75, 1.000);
               font-size:20px;
                color:#fff;
                position:absolute;
                top:0px;
                left:0;
                width:40px;
                height:40px;
                line-height:40px;
                .rounded-corners(50%);
                padding:8px 0 0 0;
                .centered();
                svg{
                    .inline-block();
                    width:60%;
                    }
                }
            }
        &.active{
            h2{
                color:@green!important;
                .toggle-icon{
                    background:rgba(44, 56, 75, 0.9)
                    }
                }
            }
        
        &:hover{
            cursor:pointer;
            }
        }
   
    }

.toggle_container{
	.block{
		.button{
			margin-top:15px!important;
			&:hover{
				.scale(1)!important;
				}
			}
		}
	}

.gallery-section{
	 h2{
        .merriweather(28px);
        color:@blueDark;
        letter-spacing:.04em;
        padding:10px 0 20px 0;
        }
    h3{
        .montserrat(20px);
        color:@green;
        padding:10px 0 20px 0;
        .uppercase();
        letter-spacing:.04em;
        font-weight:400;
        }
	}
	
 .toggle_container{
        height:0;
        overflow:hidden;
        .block{
            padding-top:30px;
            }
        }
	
#sidebar{
	padding-left:40px;
	 border-left:3px solid @grayLight;
	 line-height:1.5;
	}
	
ul.subpage-menu{
    width:100%;
    padding:0;
    margin:0 0 40px 0;
    >li{
        display:block;
        margin:0;
        padding:0;
        >a{
            display:block;
            padding:10px;
            border-top: 2px solid @defaultBG;
            .transition(all linear 130ms);
            font-size:20px;
            .text-shadow();
            &:hover{
                background:#f1f1f1;
                padding-left:15px;
                }
            }
            &.current_page_item>a, &.current_page_ancestor>a{
                background:#f1f1f1;
                padding-left:15px;
                }
            &:first-child>a{border-top:none;}
            >ul.children{display:none;}
            &.current_page_item>ul.children, &.current_page_ancestor>ul.children{
                padding:0;
                margin:0;
                display:block;
                >li{
                    >a{
                        .italic();
                        padding:10px;
                        border-top: 2px solid #e6e6e6;
                        .transition(all linear 130ms);
                        font-size:16px;
                        .text-shadow();
                        display:block;
                        &:hover{color:@accent;}
                        }
                         &:first-child>a{border-top:none;}
                         &.current_page_item>a{color:@accent;}
                    }
                }
        }
    }

ul.document-list{
     padding:0!important;
     margin:0px 0 10px 0!important;
    li{
        background:none!important;
        padding-left:0!important;
        margin-bottom:0!important;
        a{
            display:block;
            padding:10px 0 10px 0;
            .bodyfont();
            .uppercase();
            font-weight:300;
            border-bottom:1px solid @content;
            .transition();
            i{
                .inline-block();
                font-size:20px;
                color:@content;
                margin-right:10px;
                position:relative;
                top:2px;
                }
            &:hover{
                color:@content!important;
                background:@content;
                padding-left:10px;
                }
            }
        }
    }

#footer {
	position: relative;
	width:@mainWidth;
	background:@defaultBG url(images/footer-bg.png) repeat-x 0 0;	
	clear:both;
	} 

ul.social-menu{
    .inline-block();
    margin:10px;
    padding:0;
    vertical-align: middle;
    li{
        .inline-block();
        color:#fff;
        font-size:50px;
        padding:0;
        margin:0;
        position:relative;
        margin:0 3px 0 3px;
        .scale(1);
        .transition();
        &:hover{color:#fff;.scale(1.05);}
        a{
            display:block;
            text-align:left;
            text-indent:-9999px;
            width:100%;
            height:100%;
            position:absolute;
            top:0;
            left:0;
            }
        }
    }   

.badge{
	.inline-block();
	vertical-align: middle;
	margin:10px;
	width:200px;
	img{
		max-width: 100%;
		height: auto;
		}
	}
	
#copyright{
    padding:10px;
    border-top:3px solid #fff;
    .centered();
    .montserrat(10px);
    .uppercase();
    letter-spacing:.12em;
    font-weight:300;
    color:#fff;
    background:@green;
    a{
        color:#fff;
        &:hover{
            color:@greenDark;
            }
        }
    }
	
.footer-content{
	text-align:center;
	padding:60px 0 60px 0;
	background:@blueDark url(images/blue-tile.png) repeat 0 0;
	h5{
	    .merriweather(40px);
	    color:@green;
	    .italic();
	    margin-bottom:60px;
	    }
	 
	  .footer-item{
	      position:relative;
	      .montserrat(20px);
	      color:#fff;
	      .centered();
	      .uppercase();
	      .icon{
	          width:100%;
	          margin-bottom:20px;
	          .scale(1);
	          .transition();
	          svg{
	              width:120px;
	              height:auto;
	              .inline-block();
	              circle{
	                  .transition();
	                  }
	              }
	          }
	        &:hover{
	            .icon{
	                .scale(1.1);
	                svg{
	                    circle{
	                        fill:@green;
	                        }
	                    }
	                }
	            }
	      }
	}
	
.section{
    width:100%;
    position:relative;
    padding:60px 0 60px 0;
    line-height:1.5;
    &.no-pad{
        padding:0!important;
        }
    }
    
h5.section-head{
    .montserrat(34px);
    color:@blueLight;
    .uppercase();
    letter-spacing:.03em;
    margin-bottom:30px;
    }
    
.dark-blue{
        color:@blueDark!important;
        }
.white{
        color:#fff!important;
        }
.green{
    color:@green!important;
    }
    
.vert-center-wrap{
    display:table;
    height:100%;
    height: inherit;
    >.vert-center{
        display:table-cell;
        vertical-align:middle;
        
        }
    }
	
/**********PAGE STYLES**********/

.callout{
    display:block;
    .uppercase();
    position:relative;
    padding:0px 60px 0 60px;
    .centered();
    .montserrat(22px);
    color:@green;
    .quote{
        display:block;
       
        position:absolute;
        .merriweather(75px);
        color:@blueLight;
        &.open{
            top:-10px;
            left:0;
            background:url(images/open-quote-callout.png) no-repeat 0 0;
            }
        &.close{
            top:-10px;
            right:0;
            background:url(images/close-quote-callout.png) no-repeat 0 0;
            }    
        }
    &.left{
        width:100%;
        max-width:340px;
        float:left;
        margin:0 20px 15px 0;
        text-align:right;
        padding:20px 60px 0 0px;
        span.quote.open{
            display:none;
            }
        }
    &.right{
        width:100%;
        max-width:340px;
        float:right;
        margin:0 0 15px 20px;
        text-align:left;
        padding:20px 0px 0 60px;
        span.quote.close{
            display:none;
            }
        }
    &.center{
        .centered();
        width:100%;
        border-top:1px solid @blueLight;
        border-bottom:1px solid @blueLight;
        padding:15px 20px 15px 20px;
        margin:0 0 30px 0;
        .quote{
            display:none!important;
            }
        }
     }

.image-scaler{
    .scaler();
    min-width:100px;
    height:auto;
    }

/***********HEADER and NAVIGATION*********/
@menuLink: #666;
@menuLinkHover: #333;

#modal-wrap{
	background:rgba(155, 199, 71, 0.9);
	width:100%;
	height:100%;
	position: fixed;
	z-index: 9999;
	top:0;
	left:0;
	opacity:0;
	.centered();
	#modal-box{
		width:90%;
		max-width:500px;
		.inline-block();
		background:#fff;
		background-size:60% auto;
		position: relative;
		top:45%;
		transform:translateY(-50%) scale(0.85);
		opacity: 0;
		.rounded-corners(15px);
		.box-shadow-custom(0px,0px,3px,rgba(0,0,0,0.4));
		padding:40px;
		.montserrat(18px);
		line-height: 1.5;
		color:@content;
		h3{
			.montserrat(24px);
			color:@green;
			.uppercase();
			margin-bottom: 10px;
			font-weight:300;
			}
		img.logo{
			.inline-block();
			margin-bottom: 30px;
			width:80%;
			height: auto;
			}
		a#modal-close{
			color:@green;
			position: absolute;
			.inline-block();
			top:20px;
			right:20px;
			font-size:24px;
			&:hover{
				cursor: pointer;
				color:@greenDark;
				}
			}
		}
	}


#main-header{
    background:#fff;
    padding:0 0 15px 0;
    border-top:3px solid @green;
    width:100%;
    position:fixed;
    top:0;
    left:0;
    z-index:1000;
    min-height:110px;
    &.active{
    	.box-shadow-custom(0,3px,3px,rgba(0,0,0,0.2));
    	}
    &.open{
    	position:relative;
    	}
    &.slide{
    	position: absolute;
    	}
}

#logo-wrap{
    padding:20px 0 20px 0;
    .centered();
    img{
        .inline-block();
        width:90%;
        }
    }

#nav-wrap{
    text-align:right;    
    a.social-link{
    	.transition();
    	color:@greenMed;
    	font-size:24px;display:inline-block;vertical-align: middle;
    	.scale(1);
    	margin:0 4px 0 4px;
    	&:hover{
    		.scale(1.1);
    		color:@greenLight;
    		}
    	}
}

ul#menu-top-navigation{
    .inline-block();
    padding:3px 5px 7px 5px;
    margin-right:10px;
    background:@green;
    .border-radiuses(0,10px,10px,0);
    li{
        padding:0 6px 0 6px;
        &:before{
            .inline-block();
            vertical-align:middle;
            margin-right:6px;
            color:@greenDark;
            }
        a{
            .centered();
            .montserrat(12px);
            color:#fff;
            .transition();
            &:hover{
                color:@greenDark;
                }
            }
        }
    }


#menu-main-navigation-1{
    list-style:none;
    padding:0;
    margin-top:25px;
    >li{
        .inline-block();
        padding:0 5px 0 5px;
        position:relative;
        &:hover>a, &.current_page_item>a, &.current-page-ancestor>a{color:@accent!important;}
        >ul.sub-menu{
            position:absolute;
            width:200px;
            padding:0;
            margin:0;
            top:100%;
            left:0;
            z-index:500;
            display:none;
            background:@defaultBG;
            >li{
                display:block;
                padding:0;
                margin:0;
                position:relative;
                font-size:20px;
                &:first-child a{border:none;}
                >a{
                    border-top:1px solid @content;
                    color:#fff;
                    .bodyfont();
                    display:block;
                    .bold()!important;
                    .centered();
                    padding:15px 20px 15px 20px;
                    .transition(all linear 130ms);
                    .text-shadow();
                    &:hover{
                        color:@accent!important;
                        background:@defaultBG;
                        }
                    }
                }
            }
        &:first-child a{padding-left:0;}
        >a{
            .montserrat(15px);
            letter-spacing:.02em;
            display:block;
            padding:5px 0 5px 0;
            .centered();
            color:@blueDark;
            position:relative;
            .transition(all linear 140ms);
            &:before{
                content:'';
                display:block;
                height:2px;
                background:@blueLight;
                .transition(all linear 140ms);
                position:absolute;
                bottom:0;
                width:0;
                }
            &:hover{
                color:@blueLight;
                &:before{
                    width:100%;
                    }
                }
            }
        
        }
    }
 

/***********HEADER STYLES*******/

h1{
    color:#333;
    font-size:36px;
    padding:0 0 12px 0;
    }
    
h2{
    font-size:30px;
    color:#333;
    padding:0 0 12px 0;
    }
    
h3{
    font-size:24px;
    color:#333;
    padding:0 0 8px 0;
    }
    
h4{
    font-size:18px;
    padding:0 0 8px 0;
    }
    
/***********BUTTONS***********/

a.button, button.submit{
    .montserrat(20px);
    display:inline-block;
    background:#fff;
    padding:5px 20px 5px 20px;
    color:@green!important;
    font-style:normal!important;
    position:relative;
    border:1px solid @green;
    .centered();
    .uppercase();
    .border-radiuses(20px,0,20px,0);
    .transition();
    .scale(1);
    i{
        .inline-block();
        vertical-align:middle;
        margin-left:8px;
        }
    &:hover{
        background:@green;
        color:#fff!important;
        cursor:pointer;
        padding:5px 25px 5px 25px;
        .scale(1.1);
        }
    &.small{font-size:14px;.border-radiuses(12px,0,12px,0);border:1px solid @green;}
    &.full{display:block; padding:10px 20px 10px 20px;}
    }

a.block{
    width:100%;
    height:100%;
    display:block;
    position:absolute;
    top:0;
    left:0;
    z-index:100;
    }

/*********HOMEPAGE**********/


#banner{
    border-bottom:1px solid #fff;
    position:relative;
  
    &.homepage{
        height:70vh;
        .scaler();
        #banner-slide-wrap{
        	height:100%;
        	width:100%;
        	position:absolute;
        	top:0;
        	left:0;
        	z-index:-1;
        
        	.banner-slide-item{
        		width:100%;
        		height:70vh;
        	
        		top:0;
        		left:0;
        		.scaler();
        		
        		}
        	}
        }    
    &.subpage{
      
        padding:60px 0 60px 0;
        margin-bottom:45px;
        border-bottom:1px solid #fff;
        h1{
            .merriweather(48px);
            padding-bottom:0!important;
        color:#fff;
        .text-shadow();
        line-height:1.3;
        letter-spacing:.03em;
        }
        }
     &.no-pad{margin-bottom:0!important;}
    }
    
ul.slick-dots{
    width:100%;
    .centered();
    position:absolute;
    bottom:-50px;
    z-index:50;
    li{
        .inline-block();
        padding:0;
        margin:0 3px 0 3px;
        button{
            text-align:left;
            background:@accent;
            width:12px;
            height:12px;
            border:none;
            outline:none;
            display:block;
            .rounded-corners(50%);
            .transition(all linear 150ms);
            text-align:left;
            text-indent:-9999px;
            &:hover{
                cursor:pointer;
                background:#fff;
                }
            }
        &.slick-active{
            button{
                background:#fff!important;
                }
            }
        }
    }
    
.banner-content{
    display:block;
    background:rgba(155, 199, 71,0.9 );
   transform:translateY(-50%);
   top:50%;
    position:relative;
    padding:10px 30px 10px 10px;
    border-top-right-radius:50px;
    width:50%;
    text-align:right;
    border-right:3px solid #fff;
    border-top:3px solid #fff;
    border-bottom:3px solid #fff;
    h1{
        .merriweather(58px);
        color:#fff;
        letter-spacing:.02em;
        font-weight:300;
        line-height:1.2;
        }
    }
    
#about{
    .tiled();
    color:#fff;
    position:relative;
    >.central{
        padding-right:100px!important;
        font-size:110%;
        }
    }
    
#leaves-button{
    height:300px;
    position:absolute;
    overflow:visible!important;
    top:-43px;
    right:-70px;

    #leaves-svg{overflow:visible!important;}
    a{
    .centered();
    .montserrat(18px);
    .uppercase();
    color:#fff;
    padding:110px 100px 80px 80px;
    line-height:1.4;
    overflow:visible!important;
    
    &:hover{
        color:@greenLight;
        }
    span{
        .merriweather(18px);
        text-transform:none;
        display:block;
        .italic();
        }
    }
}
    
#leaves-svg{
    height:100%;
    width:auto;
     overflow:visible;
    path{
        stroke: #fff;
  stroke-width:1px;
  
  stroke-linejoin: round;
        }
    }
    
#providers{
	padding-top:20px;
	position: relative;
	
	}
	
#announcements{
	position: relative;
	top:-50px;
	.centered();
	.merriweather(18px);
	color:#fff;
	
	.inline-block();
	width:80%!important;
	background:@greenLight;
	border:2px solid #fff;
		.transition();
	.border-radiuses(20px,0,20px,0);
	.scale(1);
	&:hover{
		background: @greenMed;
		.scale(1.05);
		span.lead, span.learn{color:@greenLight;}
		}
	
	span.lead{
		.montserrat(20px);
		color:@greenDark;
		margin-right:10px;
		.uppercase();
		}
	span.learn{
		color:@greenDark;
		margin-left:10px;
		i{
			color:#fff;
			margin-left:5px;
			}
		}
	}

.announcement-item{
	padding:15px 25px 15px 25px;
	position: absolute;
	top:0;
	left:0;
	width: 100%;
	height:100%;
	}

.group-shot{
	border:none;
	max-width:100%;
	height:auto;
	display:none;
	}

.provider-item{
    width:130px;
    .inline-block();
    margin:10px 10px 20px 10px;
    position:relative;
    .transition();
    .scale(1);
    .image{
        position:relative;
        padding:0!important;
        margin-bottom:15px;
        border:3px solid @grayLight;
        }
    img{
        width:100%;
        display:block;
        line-height:1;
        }
    .fader{
        background:rgba(45, 56, 76, .9);
        color:#fff;
        width:100%;
        height:100%;
        padding:15px;
        position:absolute;
        left:0;
        .centered();
        top:0;
        padding-top:45%;
        opacity:0;
        .transition();
        span.learnmore{
            .inline-block();
            .montserrat(14px);
            border:1px solid #fff;
            .border-radiuses(15px,0px,15px,0px);
            padding:10px;
            .uppercase();
            }
        }
    h6{
        .merriweather(14px);
        color:@blueDark;
        }
    &:hover{
        .scale(1.1);
        .fader{
            opacity:1;
            }
        h6{
            color:@blueLight;
            }
        }
    }
    
#awards{
    background:@grayLight;
    }

ul.gallery{
    li{
        padding:0;
        margin:10px;
        .inline-block();
        vertical-align:middle;
        max-width:200px;
        img{
            max-width:100%;
            height:auto;
            }
        }
   
    }
    
.title-bar{
    padding:5px 0 5px 0;
    background:@green;
    border-top:3px solid #fff;
    border-bottom:3px solid #fff;
    h5{
        margin:0!important;
        padding:0!important;
        }
    }
    
.acf-map{
    min-height:350px;
    width:100%;
    &.homepage{
        height:400px;
        }
    &.contact{
        height:500px;
        }
    &.single{
        height:500px;
        }
    }

.gm-style-iw, #main-content .gm-style{
    padding:20px;
    .merriweather(16px);
    color:@content;
    a{color:@blueDark;&:hover{color:@green;}}
    img{
    	border:none!important;
    	}
    img.marker-image{
        
        max-width:80%!important;
        height:auto;
        border:3px solid rgba(100, 105, 127, 1.000)!important;
        }
    h4{
        .montserrat(20px);
        color:@blueDark;
        }
    span.details{
        display:block;
        i{color:@green;
        margin-right:8px;}
        }
    a.button{margin-top:15px;}
    
    }
    
/***********SERVICES*********/

#service-item-wrap{
>.service-item{
    position:relative;
    .scaler();
    height:400px;
    overflow:hidden;
    border-bottom:2px solid #fff;
    .item-content{
        display:block;
        width:100%;
        height:100%;
        background:rgba(0,0,0,0.4);
        color:#fff;
        position:absolute;
        top:0;
        left:0;
        .scale(1);
        .centered();
        .transition();
        .centered();
        >div{
        	position:relative;
            top:50%;
            transform:translateY(-50%);
            .merriweather(14px);
            .italic();
            .inline-block();
            width:80%;
        span{
            .montserrat(20px);
            .inline-block();
            .uppercase();
            padding:10px;
            font-style:normal;
            border:1px solid #fff;
            .border-radiuses(20px,0,20px,0);
            
            .transition();
            }
        }  
        }
    &:hover{
        .item-content{
            .scale(1.2);
            background:rgba(0,0,0,0.8);
            span{
                border:3px solid @green;
                background:@green;
                }
            }
        }
   
    &:nth-child(odd){
        border-right:2px solid #fff;
        }
    }
}

/***********RESOURCES******/
#page-nav{
	padding:0!important;
	padding-bottom:50px!important;
	ul.page-navigation{
		padding:0!important;
		margin:0!important;
		li{
			padding:0!important;
			margin:0!important;
			i{
				display:none!important;
				}
			a{
				display:block!important;
				border:2px solid #fff;
				background:rgba(155, 199, 71, 1.000);
				.centered();
				.montserrat(24px);
				color:#fff!important;
				.transition();
				padding:10px 20px 10px 20px;
				.uppercase();
				&:hover{
					background:rgba(69, 119, 56, 1.000);
					}
			}
			}
		}
	}

#forms{
    padding:60px 0 60px 0;
    .left{border-right:2px solid @grayLight;}
    }

.form-list-wrap{
    width:70%;
    .inline-block();
    text-align:left;
    h5{
        .montserrat(16px);
        color:@blueDark;
        .uppercase();
        padding-bottom:10px;
        }
    }
    
ul.form-list{
    margin-bottom:30px;
    li{
        i.fa-chevron-right{display:none!important;}
        background:url(images/icons/checkmark.svg) no-repeat top left!important;
        background-size:30px auto!important;
        color:@content;
        padding-left:40px!important;
        span{color:@blueLight;}
        span,a{
            .inline-block();
            margin-right:10px;
            vertical-align:middle;
            }
        a{i{font-size:20px;display:inline-block;}}
        }
    }

#insurance{
    background:@grayLight;
    padding:60px 0 60px 0;
    ul{
        -webkit-column-count: 3; /* Chrome, Safari, Opera */
        -moz-column-count: 3; /* Firefox */
        column-count: 3;
        li{
            padding:0 30px 0 30px!important;
            margin-bottom:12px!important;
            i{
                color:@green!important;
                }
            }
        }
    }
    
#patient-links{
    >div{
        position:relative;
        background:rgba(31, 38, 63, 1.000);
        .transition();
        padding:20px 0 20px 0;
        &.right{
            border-left:1px solid #fff;
            }
        &.left{
            border-right:1px solid #fff;
            }
        
        img{
            border:none!important;
            max-width:150px;
            .inline-block();
            .scale(1);
            .transition();
            }
        span{
            .scale(1);
            .transition();
            display:block;
            .montserrat(20px);
            .uppercase();
            color:#fff;
            letter-spacing:.05em;
            }
        &:hover{
            background:rgba(31, 38, 63, 0.8);
            img,span{
                .scale(1.1);
                }
            }
        }
    }
/**********ITEMS and WIDGETS**************/

.item{
    width:100%;
   
}

.item.blog{
	padding: 10px;
	height:500px;
	>div{
		.scaler();
		position: relative;
		
		.transition();
		.scale(1);
		height:100%;
		&:after{
			content:'';
			display: block;
			background: rgba(32, 37, 64, 0.5);
			.transition();
			position: absolute;
			top:0;
			left:0;
			z-index: 1;
			width: 100%;
			height:100%;
			}
		.details{
			.transition();
			color:#fff;
			position: absolute;
			bottom:0;
			z-index: 5;
			padding:20px;
			
			.readmore{
				.montserrat(15px);
				font-size: inherit;
				.uppercase();
				letter-spacing: .05em;
				margin-left: 8px;
				.inline-block();
				.transition();
				position: relative;
				z-index: 0;
				vertical-align: bottom;
				display: none;
				}
			h5{
				.montserrat(24px);
				.uppercase();
				padding-bottom:20px;
				margin-bottom:20px;
				border-bottom: 2px solid #fff;
				line-height: 1.2;
				}
			}
		&:hover{
			.readmore{
				color:@blueLight;
				}
			.scale(1.02);
			.details{
				bottom:20px;
				.categories{position: relative;z-index: 200;}
				}
			&:after{
				background:rgba(32, 37, 64, 0.85);
				}
			}
		}
	}

.categories{
				.montserrat(16px);
				.uppercase();
				margin-top:15px;
				letter-spacing: .08em;
				}	



.item.blog {
    display: inline-block;
    
	-webkit-transition:1s ease all;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.item.blog{max-width:100%;}



.widget{
    margin-bottom:60px;
    .bodyfont();
    font-size:15px;
    color:@content;
    img{max-width:100%;height:auto;}
    a.button{margin-top:15px;}
    &#testimony{
        .italic();
        }
    a{
    	color:@blueDark;
    	&:hover{
    		color:@green;
    		}
    	}
}

ul.widget-list{
	li{
		a{
			display: block;
			.montserrat(16px);
			color:@blueDark!important;
			letter-spacing: .08em;
			padding:8px;
			border-bottom:1px solid @grayLight;
			font-style:normal;
			.transition();
			&:hover{
				background:@grayLight;
				
				}
			}
		&:first-child{
			a{
				border-top:1px solid @grayLight;
				}
			}
		}
	}


h5.icon-title{
        .montserrat(20px)!important;
        color:@green!important;
        .uppercase();
        .centered();
        padding:80px 0 20px 0!important;
        font-style:normal!important;
        letter-spacing:.05em;
        &#sidebar-locations{
            background:url(images/icons/locations.svg) no-repeat top center;
            background-size:80px auto;
            }
        &#sidebar-testimonials{
            background:url(images/icons/testimonials.svg) no-repeat top center;
            background-size:80px auto;
            }
        &#sidebar-appointments{
            background:url(images/icons/calendar.svg) no-repeat top center;
            background-size:80px auto;
            }
        &#patient-forms{
            background:url(images/icons/forms.svg) no-repeat top center;
            
            }
        &#patient-handouts{
            background:url(images/icons/handouts.svg) no-repeat top center;
            
            }
        &#office-tours{
            background:url(images/icons/camera.svg) no-repeat top center;
            
            }
         &#accepted-insurance{
            background:url(images/icons/insurance.svg) no-repeat top center;
            
            }
        &.page{
            font-size:26px!important;
            padding:100px 0 20px 0!important;
            background-size:100px auto!important;
            }
    }

ul.location-list{
    li{
        color:@content;
        padding:20px 0 20px 0;
        border-bottom:1px solid @grayLight;
        span.details{
        margin:5px 0 5px 0;
        display:block;
        i{color:@green;
        margin-right:8px;
        .inline-block();
        }
        }
        h6{
            .merriweather(20px);
            color:@blueLight;
            .italic();
            margin-bottom:5px;
            }
        &:last-child{
            border-bottom:none;
            }
        }
    &.page{
        li{
            .inline-block();
            max-width:260px;
            padding:0!important;
            margin:15px!important;
            border:none!important;
            i{display:none;}
            a{
            	color:@green;
            	&:hover{color:@greenDark;}
            	}
            img{
                .inline-block();
                width:100%;
                height:auto;
                margin-bottom:15px;
                .scale(1);
                .transition();
                border:2px solid @grayLight;
                &:hover{
                    .scale(1.1);
                    }
                
                }
            .no-photo{
                    width:100%;
                    height:196px;
                    border:2px solid @grayLight;
                    background:#fff;
                    .centered();
                     .scale(1);
                    .transition();
                    padding-top:20%;
                    margin-bottom:15px;
                    &:hover{
                    .scale(1.1);
                   
                    }  
                    img{
                        height:60%;
                        width:auto;
                        border:none!important;
                        .scale(1)!important;
                        }
                    }
            a.button{
                margin-top:15px;
                }
            }
        }
    }
 
 #tours{
     
     }
     
.tour-item{
    position:relative;
    width:100%;
    max-width:400px;
    margin:20px;
    .inline-block();
    .image-scaler{
        width:100%;
        height:250px;
        border:3px solid @grayLight;
        margin-bottom:20px;
        .scale(1);
        .transition();
        }
    h5{
        .merriweather(18px);
        .italic();
        color:@blueDark;
        .transition();
        }
    &:hover{
        .image-scaler{.scale(1.05);}
        h5{color:@blueLight;}
        }
    }
    
/********CONTACT PAGE********/

#contact-locations{
    background:#f8f8f8;
    }
    
/********LOCATIONS*******/

#location-details{
    span.details{
        display:block;
        i{color:@green;
        margin-right:8px;}
        }
    }
    
ul.gallery{
    li{
        outline:none;
        margin:15px;
        a{outline:none;}
        img{
            outline:none;
            border:2px solid @grayLight;
            .scale(1);
            .transition();
            &:hover{
                .scale(1.1);
                }
            }
        }
    }
    
/********PRODUCTS********/
#product-table{
	border-collapse:collapse;
	td{
		border:2px solid @grayLight;
		padding:20px;
		 h5{
            .montserrat(18px);
            .uppercase();
            }
        img{
            width:100%;
            border:none!important;
            height:auto;
            max-width:250px;
            margin-bottom:20px;
            .inline-block();
            }
		}
	}

.product-item{
    .inline-block();
    margin:20px;
    width:100%;
    
    max-width:300px;
    background:#fff;
    padding:30px;
    position:relative;
    .scale(1);
    .transition();
    h5{
            .montserrat(18px);
            .uppercase();
            }
        img{
            width:100%;
            height:auto;
            max-width:250px;
            margin-bottom:20px;
            .inline-block();
            }
    &.main{
        .centered();
        h5{
            .montserrat(18px);
            .uppercase();
            }
        img{
            width:100%;
            height:auto;
            max-width:250px;
            margin:0;padding:0;line-height:1;
            .inline-block();
            }
        &:hover{
            .scale(1.1);
            background:#ebebeb;
            }
        }
    .no-photo{
                    width:100%;
                    height:200px;
                    border:2px solid @grayLight;
                    background:#fff;
                    .centered();
                     .scale(1);
                    .transition();
                    padding:30px;
                    margin-bottom:15px;
                   padding-top:40px;
                    img{
                        width:50%;
                        border:none!important;
                        }
                    }
    }
    
.mason-grid{
    width:100%;
    margin:0px auto;
    }
    
.product-item.grid-item{
    margin:20px 0 20px 0;
    .inline-block();
    }
    
/********TESTIMONIALS******/

.testimonial-item{
    background:#f8f8f8;
    border:2px solid #ebecec;
    padding:40px;
    margin:20px 0 20px 0;
    }
    

    


/********ALIGNMENT********/

.alignleft {
    display: inline;
	float: left;
	margin-right: 1.625em;
	max-width:300px!important;
	height:auto;
}
.alignright {
	display: inline;
	float: right;
	margin-left: 1.625em;
	max-width:300px!important;
	height:auto;
}
.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

img.alignleft,
img.alignright,
img.aligncenter {
    margin-bottom: 1.625em;
}	

.clear{
    clear:both;
    }  

.wp-caption-text{
    font-size:12px;
    .italic();
    }
    
figure{
    -webkit-margin-before: 0;
-webkit-margin-after: 0;
-webkit-margin-start: 0;
-webkit-margin-end: 0;
    }
    
.fixed-bg{
    background-attachment:fixed!important;
    }
    
.scaler{
    position:relative;
    -webkit-background-size: cover!important;
    -moz-background-size: cover!important;
    -o-background-size: cover!important;
    background-size: cover!important;
}

.centered{
    text-align:center;
    }
    
.marker {
  background-image: url(images/map-icon.svg);
  background-size: cover;
  width: 36px;
  height: 53px;
  cursor: pointer;
}

#main-content ul.awards{
	margin:0;
	padding:0;
	li{
		padding:0;
		margin:15px;
		.inline-block();
		width:280px;
		height:280px;
		.centered();
		line-height: 280px;
		border:2px solid @grayLight;
		overflow: hidden;
		position: relative;
		i{display: none;}
		img{
			border:none;
			.inline-block();
			width:90%;
			height: auto;
			}
		.fader{
			background:rgba(44, 55, 76, 0.9);
			.transition();
			width: 100%;
			height:100%;
			position: absolute;
			top:0;
			left:-100%;
			opacity:0.5;
			.centered();
			z-index: 10;
			padding:10px;
			h6{
				position: relative;
				top:50%;
				transform:translateY(-50%);
				.montserrat(16px);
				.uppercase();
				color: #fff;
				line-height: 1.2;
				}
			}
		&:hover{
			.fader{
				left:0;
				opacity:1;
				}
			}
		}
	}
	
.gray-section{
	background:rgba(235, 235, 235, 1.000);
	padding:60px 0 60px 0;
	img{
		border:none!important;
		
		}
	a.button{
		background:@green!important;
		border:2px solid #fff!important;
		color:#fff!important;
		&:hover{
			background:@blueDark!important;
			}
		}
	}

.team-link-image{
	width:80%!important;
	max-width: 240px!important;
	.inline-block()!important;
	margin-bottom:25px;
	}